import React from "react";
import { FormControl } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";

export default function TextBoxWithValidator({
  label,
  value,
  name,
  onInput,
  style,
  rows = 1,
  multiline = false,
  readOnly = false,
  required = true,
  id,
  type = "input",
  fullWidth = false,
  variant = "outlined",
}) {
  return (
    <FormControl fullWidth={true}>
      <TextValidator
        fullWidth={fullWidth}
        autoComplete="new-password"
        id={id && id}
        className={readOnly ? "sm-disabled" : ""}
        required={required}
        inputProps={{ readOnly: readOnly }}
        onInput={onInput}
        style={{ ...style }}
        type={type}
        value={value}
        multiline={multiline}
        min={rows}
        size="small"
        label={label}
        variant={variant}
        name={name}
        validators={required === true ? ["required"] : []}
        errorMessages={required === true ? ["This field is required"] : []}
      />
    </FormControl>
  );
}
