import React, { useEffect, useState } from 'react';
import MenuIds from './MenuIds';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import CoverSearch from '../../Pages/CoverSearch/CoverSearch';
import UserManagementUser from '../../Pages/UserManagement/UserManagementUser';
import UserManagementRole from '../../Pages/UserManagement/UserManagementRole';
import Onboarding from '../../Pages/ReviewData/ReviewData';
import EblastThreshold from '../Eblast/EblastThreshold';
import SendEmail from '../../Pages/SendEmail/SendEmail';
import Campaign from '../../Pages/Campaign/Campaign';
import Provider from '../../Pages/Provider/Provider';
import Container from '@material-ui/core/Container';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import AuthService from '../../Services/authService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Storage from '../../storage';
import Reporting from '../Reporting/Reporting';
import SettingsIcon from '@material-ui/icons/Settings';
import Assessment from '@material-ui/icons/Assessment';
import Build from '@material-ui/icons/Build';
import Dashboard from '@material-ui/icons/Dashboard';
import Book from '@material-ui/icons/Book';
import Collapse from '@material-ui/core/Collapse';
import './MainMenu.scss';
import NotificationLogs from '../NotificationLogs/NotificationLogs';
import WSMaster from '../WebSchedulerConfig/WSMaster';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InfoIcon from '@material-ui/icons/Info';
import Coverlookup from '../../Pages/Coverlookup/Coverlookup';
import CoverlookupAddLocationProvider from '../../Pages/Coverlookup/AddLocationProvider';
import ReminderExclusion from '../../Pages/ReminderExclusion/ReminderExclusion';
import PatientForms from '../PatientForms';
import TriageReportMaster from '../../Pages/TriageReports/TriageReportMaster';
import { useQuery } from '../../ContextLib/contextHooks';
import TwoWayText from '../../Pages/2WayText/TwoWayText';
import LocationMapping from '../PmsConfig/MappingPage/LocationMapping';
import ProviderMapping from './../PmsConfig/MappingPage/ProviderMapping';
import AppointmentReasonMapping from './../PmsConfig/MappingPage/AppointmentReasonMapping';
import ShadowMgmt from './../PmsConfig/ShadowManagement/ShadowMgmt';
import WebScheduler from '../PmsConfig/WebScheduler/WebScheduler';
import EdenWritebackStatus from '../PmsConfig/EdenWriteBackStatus/EdenWritebackStatus';
import WritebackSetting from '../PmsConfig/WritebackSetting/WritebackSetting'; 
import TcDataViewer from './../PmsConfig/TcDataViewer/TcDataViewer';
import ShadowTeamBulkUpdate from '../PmsConfig/ShadowBulkUpdate/ShadowTeamBulkUpdate';
import ShadowBulkOperationLanding from './../PmsConfig/ShadowBulkUpdate/ShadowBulkOperationLanding';
import SyncCRMCatalog from '../../Pages/Coverlookup/SyncCRMCatalog';
import HfConfigurations from '../PmsConfig/PmsGenSettings/HfConfigurations';
import ScriptGenerator from '../../Components/ScriptGenerator/ScriptGenerator';

const drawerWidth = 320;
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    typography: {
       flexGrow: 1,
       align: "center",
       verticalAlign: 'bottom',
       justifyContent: "center",
       width: 800
    },
    newnested: {
      paddingLeft: theme.spacing(10),
    },
    fixedContainer: {
      width: `calc(100vw - ${theme.spacing(7) + 1}px)`,
      maxWidth: 'unset',
    },
    fixedContainerShift: {
      width: `calc(100vw - ${drawerWidth + theme.spacing(2)}px)`,
    }
  }),
);

export default function MiniDrawer() {
  const query = useQuery();

  const classes = useStyles();
  const userRole = Storage.getItem("userRole");
  const [open, setOpen] = useState(false);
  const [menu, Submenu] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [expandScheduleSetup, setExpandScheduleSetup] = useState(false);
  const [expandPMSConfig, setexpandPMSConfig] = useState(false);
  const [userexpandScheduleSetup, setuserExpandScheduleSetup] = useState(false);
  const [coverexpandScheduleSetup, setcoverExpandScheduleSetup] = useState(false);
  const [editexpandScheduleSetup, seteditExpandScheduleSetup] = useState(false);
  const [coverLookupExpand, setCoverLookupExpand] = useState(false);
  const [triageScheduleSetup, setTriageScheduleSetup] = useState(false);
  const [triageCoverScheduleSetup, setTriageCoverScheduleSetup] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [env] = useState(`${process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT}`);

  const setActive = (menuIdx) => {
    Submenu(menuIdx);
  };

  const _onLogout = () => {
    AuthService.logoutUser();
  }

  const _handleDrawerOpen = () => {
    setOpen(true);
  };

  const _handleDrawerClose = () => {
    setOpen(false);
    setExpandScheduleSetup(false);
    setuserExpandScheduleSetup(false);
    setcoverExpandScheduleSetup(false);
    setCoverLookupExpand(false);
	setTriageScheduleSetup(false);
	setTriageCoverScheduleSetup(false);
	setexpandPMSConfig(false);
  };

  const _handleTriageMasterReport = () =>{
	_triageCollapseSchedule();
	setActive(19);
  }

  const _handleClose = () => {
    setOpenDialog(false);
  };

  const _handleCollapseSchedule = () => {
    if (!open) {
      setOpen(true);
    }
    setExpandScheduleSetup(!expandScheduleSetup);
  };
  const _pmsCollapseSchedule = () => {
    if (!open) {
      setOpen(true);
    }
    setexpandPMSConfig(!expandPMSConfig);
  };
  const _userhandleCollapseSchedule = () => {
    if (!open) {
      setOpen(true);
    }
    setuserExpandScheduleSetup(!userexpandScheduleSetup);
  };
  const _triageCollapseSchedule = () => {
    if (!open) {
      setOpen(true);
    }
    setTriageScheduleSetup(!triageScheduleSetup);
  };
  const _triageCoverScheduleSetup = () => {
    if (!open) {
      setOpen(true);
    }
    setTriageCoverScheduleSetup(!triageCoverScheduleSetup);
  };

  const _coverhandleCollapseSchedule = () => {
    if (!open) {
      setOpen(true);
    }
    setcoverExpandScheduleSetup(!coverexpandScheduleSetup);
  };
  const _edithandleCollapseSchedule = () => {
    if (!open) {
      setOpen(true);
    }
    seteditExpandScheduleSetup(!editexpandScheduleSetup);
  };

  const _handleCoverLookupExpand = () => {
    if (!open) {
      setOpen(true);
    }
    setCoverLookupExpand(!coverLookupExpand);
  };

  const _onMenuChange = (menuId) =>
  {
    Submenu(menuId)
  }
  
  useEffect(()=>{
    const _menu = query.get('menu');
    setActive(parseInt(_menu));
  },[]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={_handleDrawerOpen}
            edge="start"
            
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img
          alt="Site Logo"
          src={'4PC_Logo-White.png'} width="150px" height="19.14px"/>         
          <Icon/>
          <Typography variant="h6" className={classes.typography} noWrap>
            Support Dashboard                            
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={_handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
           {/* {(userRole.includes("Admin") || userRole.includes("CommConfig")) && <ListItem button key='Communication Type' onClick={() => { setActive(0); }}>
              <Tooltip title="Communication Type">
                <ListItemIcon><LibraryBooksTwoToneIcon/></ListItemIcon>
                </Tooltip> 
                <ListItemText primary='Communication Type' />
            </ListItem>}
            {(userRole.includes("Admin") || userRole.includes("ProvConfig")) && <ListItem button key='Provider Type' onClick={() => { setActive(1); }}>
              <Tooltip title="Provider Type">
                <ListItemIcon><MenuBookIcon/></ListItemIcon>
                </Tooltip> 
                <ListItemText primary='Provider Type' />
            </ListItem>} */}
            {/* {(userRole.includes("Admin") || userRole.includes("SendEmail")) && <ListItem button key='Email Testing' onClick={() => { setActive(2); }}>
              <Tooltip title="Email Testing">
                <ListItemIcon><Icon>send</Icon></ListItemIcon>
              </Tooltip> 
              <ListItemText primary='Email Testing' />
            </ListItem>
            } */}
          {/*   {(userRole.includes("Admin") || userRole.includes("AddCover")) && <ListItem button key='Add Coverkey' onClick={() => { setActive(3); }}>
              <Tooltip title="Add Coverkey">
                <ListItemIcon><PostAddIcon/></ListItemIcon>
              </Tooltip>  
              <ListItemText primary='Add Coverkey' />
            </ListItem>}
            {/* {(userRole.includes("Admin") || userRole.includes("AddCover")) && <ListItem button key='Upload Covers' onClick={() => { setActive(4); }}>
              <Tooltip title="Upload Covers">
                <ListItemIcon><CloudUploadIcon/></ListItemIcon>
              </Tooltip>  
              <ListItemText primary='Upload Covers' />
            </ListItem>} */}
            {/* <ListItem button key='Search' onClick={() => { setActive(5); }}>
              <Tooltip title="Search Coverkey">
                <ListItemIcon><FindInPageTwoToneIcon/></ListItemIcon>
              </Tooltip>  
              <ListItemText primary='Search' />
            </ListItem> */}
            {/* {(userRole.includes("Admin") || userRole.includes("Eblast")) && <ListItem button key='Eblast' onClick={() => { setActive(6); }}>
              <Tooltip title="eBlast Threshold">
                <ListItemIcon><SettingsEthernetIcon/></ListItemIcon>
              </Tooltip>  
              <ListItemText primary='Eblast' />
            </ListItem>}   */}
            {/* {(userRole.includes("Admin")) && <ListItem button key='Add Users' onClick={() => { setActive(7); }}>
              <Tooltip title="Add Users">
              <ListItemIcon><GroupAddIcon/></ListItemIcon>
              </Tooltip> 
              <ListItemText primary='Add Users' />
            </ListItem>
            }
            {(userRole.includes("Admin")) && <ListItem button key='Change User Roles' onClick={() => { setActive(8); }}>
              <Tooltip title="Change User Roles">
              <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
              </Tooltip> 
              <ListItemText primary='Change User Roles' />
            </ListItem>
            } */}
            {/* {(userRole.includes("Admin") || userRole.includes("CommConfig")) && <ListItem button key='Key Creation' onClick={() => { setActive(9); }}>
              <Tooltip title="Key Creation">
              <ListItemIcon><FolderSharedIcon /></ListItemIcon>
              </Tooltip> 
              <ListItemText primary='Key Creation' />
            </ListItem>
            } */}
            {/* {(userRole.includes("Admin") || userRole.includes("CommConfig")) && <ListItem button key='Patient Engagement Data' onClick={() => { setActive(10); }}>
              <Tooltip title="Patient Engagement Data">
              <ListItemIcon><InsertChartIcon /></ListItemIcon>
              </Tooltip> 
              <ListItemText primary='Patient Engagement Data' />
            </ListItem>
            } */}
              <ListItem button key='Cover Information' onClick={() => { _coverhandleCollapseSchedule(); }}>
              <Tooltip title="Cover Information">
              <ListItemIcon><InfoIcon /></ListItemIcon>
              </Tooltip> 
              <ListItemText primary='Cover Information' onClick={() => { _coverhandleCollapseSchedule(); }}/>   
             </ListItem>
            <Collapse in={coverexpandScheduleSetup} timeout="auto">
              <ListItem button>
                <ListItemText secondary='2-Way Text' onClick={() => { setActive(18); }} className="li-schedule-setup"/>
              </ListItem>
              <ListItem button>
                <ListItemText secondary='View Communication Status' onClick={() => { setActive(5); }} className="li-schedule-setup"/>
              </ListItem>
              {/* {(userRole.includes("Admin") || userRole.includes("CommConfig") || userRole.includes("ProvConfig")) ?
              <> */}
              <ListItem button key='Edit Communication Status' onClick={() => { _edithandleCollapseSchedule(); }}>
                <ListItemText secondary='Edit Communication Status'   className="li-schedule-setup"/>
              </ListItem>
              <Collapse in={editexpandScheduleSetup} timeout="auto">
              {/* {(userRole.includes("Admin") || userRole.includes("CommConfig")) &&  */}
              <ListItem button>
                <ListItemText secondary='Communication Preference' onClick={() => { setActive(0); }} className={classes.newnested}/>
              </ListItem>
              {/* } */}
              {/* {(userRole.includes("Admin") || userRole.includes("ProvConfig")) &&  */}
              {((env.includes("development") || env.includes("qa") || env.includes("staging"))) &&
              <ListItem button>
                <ListItemText secondary='Provider Type' onClick={() => { setActive(1); }} className={classes.newnested}/>
              </ListItem>}
              {/* } */}
              <ListItem button>
                <ListItemText secondary='Reminder Exclusion' onClick={() => { setActive(-1); }} className={classes.newnested}/>
              </ListItem>
              </Collapse>
              {/* </> : null } */}
              {/* {(userRole.includes("Admin") || userRole.includes("CommConfig")) && */}
              <ListItem button>
                <ListItemText secondary='Patient Engagement Data' onClick={() => { setActive(10); }} className="li-schedule-setup"/>
              </ListItem>
              {/* } */}
              {/* {(userRole.includes("Admin") || userRole.includes("Eblast")) && */}
              <ListItem button>
                <ListItemText secondary='Eblast Threshold' onClick={() => { setActive(6); }} className="li-schedule-setup"/>
              </ListItem>
              {/* } */}
              {/* {(userRole.includes("Admin") || userRole.includes("SendEmail")) && */}
              <ListItem button>
                <ListItemText secondary='Email Testing' onClick={() => { setActive(2); }} className="li-schedule-setup"/>
              </ListItem>
              {/* } */}
              {/* {(userRole.includes("Admin") || userRole.includes("CommConfig")) && */}
              <ListItem button>
                <ListItemText secondary='Key Creation' onClick={() => { setActive(9); }} className="li-schedule-setup"/>
              </ListItem>
              {/* } */}
              <ListItem button onClick={() => { _handleCoverLookupExpand(); }}>
                <ListItemText secondary='Cover Lookup' onClick={() => { setActive(3); }} className="li-schedule-setup"/>
              </ListItem>
              <Collapse in={coverLookupExpand} timeout="auto">
                <ListItem button>
                  <ListItemText secondary='Add Location/Provider' onClick={() => { setActive(4); }} className={classes.newnested}/>
                </ListItem>
                <ListItem button>
                  <ListItemText secondary='Sync CRM Catalog' onClick={() => { setActive(23); }} className={classes.newnested}/>
                </ListItem>
              </Collapse>
              <ListItem button >
                <ListItemText secondary='Notification Logs' onClick={() => { setActive(22); }} className="li-schedule-setup"/>
              </ListItem>                 
           
            </Collapse>
          <ListItem button key='Patient Forms' onClick={() => setActive(17)}>
            <Tooltip title="Patient Forms">
              <ListItemIcon><Book /></ListItemIcon>
            </Tooltip>
            <ListItemText primary='Patient Forms' onClick={() => setActive(17)} />
          </ListItem>    

				  <ListItem button key='Online Booking YSetup' onClick={() => { _handleCollapseSchedule(); }}>
					  <Tooltip title="Online Booking Setup">
						  <ListItemIcon><SettingsIcon /></ListItemIcon>
					  </Tooltip>
					  <ListItemText primary='Online Booking Setup' onClick={() => { _handleCollapseSchedule(); }} />
				  </ListItem>
				  <Collapse in={expandScheduleSetup} timeout="auto">
					  <ListItem button>
						  <ListItemText secondary='Appointment Types' onClick={() => { setActive(11); }} className="li-schedule-setup" />
					  </ListItem>
					  <ListItem button>
						  <ListItemText secondary='Insurance Types' onClick={() => setActive(12)} className="li-schedule-setup" />
					  </ListItem>
					  <ListItem button>
						  <ListItemText secondary='Patient Information' onClick={() => setActive(13)} className="li-schedule-setup" />
					  </ListItem>
					  <ListItem button>
						  <ListItemText secondary='General' onClick={() => setActive(14)} className="li-schedule-setup" />
					  </ListItem>
					  {/* <ListItem button>
              <ListItemText secondary='Share' className="li-schedule-setup"/>
             </ListItem> */}
					  <ListItem button>
						  <ListItemText secondary='Schedule' onClick={() => {setActive(16); _handleDrawerClose();}} className="li-schedule-setup" />
					  </ListItem>
            {(userRole?.includes("Admin")) ? 
              <>
              <ListItem button>
                <ListItemText secondary='Locator Integration' onClick={() => { setActive(15); }} className="li-schedule-setup" />
              </ListItem>
              </>
              : null
            } 
            <ListItem button>
						  <ListItemText secondary='Script Generator' onClick={() => {setActive(24); _handleDrawerClose();}} className="li-schedule-setup" />
					  </ListItem>
            <ListItem button>
              <ListItemText secondary='Appointment Type Mapping' onClick={() => {setActive(25); _handleDrawerClose();}} className="li-schedule-setup" />
            </ListItem>
				  </Collapse>


						  <ListItem button key='PMS Config'  onClick={() => { _pmsCollapseSchedule(); }}>
							  <Tooltip title="PMS Config">
								  <ListItemIcon><Build /></ListItemIcon>
							  </Tooltip>
							  <ListItemText primary='PMS Config'  onClick={() => { _pmsCollapseSchedule(); }} />
						  </ListItem>
              <Collapse in={expandPMSConfig} timeout="auto">
            <ListItem button>
              <ListItemText
                secondary="Web Scheduler / Shadow DB Mapping"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_WEBSCHED_MAP)
                }
                className="li-schedule-setup"
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                secondary="Appointment Reason Mapping"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_APPOINTMENTREASON_MAP)
                }
                className="li-schedule-setup"
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                secondary="Location Mapping"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_LOCATION_MAP)
                }
                className="li-schedule-setup"
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                secondary="Provider Mapping"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_PROVIDER_MAP)
                }
                className="li-schedule-setup"
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                secondary="Shadow Management V2"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_SHADOWMANAGEMENT)
                }
                className="li-schedule-setup"
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                secondary="TC Data Viewer"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_TC_DATAVIEWER)
                }
                className="li-schedule-setup"
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                secondary="Writeback Settings"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_WRITEBACK_SETTING)
                }
                className="li-schedule-setup"
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                secondary="Eden WB Status"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_EDEN_WRITEBACK)
                }
                className="li-schedule-setup"
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                secondary="Shadow Team Bulk Operation"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_BULKUPDATEPAGE)
                }
                className="li-schedule-setup"
              />
            </ListItem>

            { env !== "production" && 
            <>
            {(userRole?.includes("Admin")) &&
              <ListItem button>
              <ListItemText
                secondary="Hangfire Job Settings"
                onClick={() =>
                  setActive(MenuIds.MenuIds.SHADOWCONFIG_PMSHFCONFIGURATIONS)
                }
                className="li-schedule-setup"
              />
            </ListItem>
            }
            </>
            }

          </Collapse>
				
          {(userRole?.includes("Admin")) &&
					  <>
						  <ListItem button key='Triage Reports'>

							  <Tooltip title="Triage Reports">
								  <ListItemIcon onClick={() => { _handleTriageMasterReport(); }}><Assessment /></ListItemIcon>
							  </Tooltip>
							  <ListItemText primary='Triage Reports' onClick={() => { _handleTriageMasterReport(); }} />
						  </ListItem>
						  <Collapse in={triageScheduleSetup} timeout="auto">
							  <ListItem button>
								  <ListItemText secondary='Shadow Upload' onClick={() => { setActive(20); }} className="li-schedule-setup" />
							  </ListItem>
							  <ListItem button>
								  <ListItemText secondary='Shadow Detail' onClick={() => { setActive(21); }} className="li-schedule-setup" />
							  </ListItem>
						  </Collapse>
					  </>
				  }   

             {env !== "production" && <ListItem button key='Master Services'>
              <Tooltip title="Master Services">
              <ListItemIcon><Dashboard /></ListItemIcon>
              </Tooltip> 
              <ListItemText primary='Master Services'/>   
             </ListItem>

			 }   
             {(userRole?.includes("Admin")) ? 
              <>
              <ListItem button key='User Management' onClick={() => { _userhandleCollapseSchedule(); }}>
              <Tooltip title="User Management">
              <ListItemIcon><PersonAddIcon /></ListItemIcon>
              </Tooltip> 
              <ListItemText primary='User Management' onClick={() => { _userhandleCollapseSchedule(); }}/>   
             </ListItem>
            <Collapse in={userexpandScheduleSetup} timeout="auto">
            <ListItem button>
              <ListItemText secondary='Add Users' onClick={() => { setActive(7); }} className="li-schedule-setup"/>
             </ListItem>
             <ListItem button>
              <ListItemText secondary='User Roles' onClick={() => { setActive(8); }} className="li-schedule-setup"/>
             </ListItem>
      
            </Collapse>
            </>
              : null
            }  
            <ListItem button key='Logout' onClick={() => { setOpenDialog(true); }}>
              <Tooltip title="Logout">
                <ListItemIcon><ExitToAppTwoToneIcon/></ListItemIcon>
              </Tooltip>  
              <ListItemText primary='Logout' />
            </ListItem>
            
        </List>
        <Divider />
      </Drawer>
     <main className={classes.content}>       
      <div className={classes.toolbar} /> 
      <Container className="container mx-auto" >
        {menu === 0 && <Campaign/>}
        {menu === 1 && <Provider/>}
        {menu === 2 && <SendEmail/>}
        {menu === 4 && <CoverlookupAddLocationProvider/>}
{/*         {menu === 3 && <CoverAdd/>}
        {menu === 4 && <CoverAddMultiple/>} */}
        {menu === 5 && <CoverSearch/>}
        {menu === 6 && <EblastThreshold/>}
        {menu === 7 && <UserManagementUser/>}
        {menu === 8 && <UserManagementRole/>}
        {menu === 9 && <Onboarding/>}
        {/* {menu === 11 && <AppointmentTypes/>} */}
        {((menu >= 11 && menu <= 16) || menu === 25)  &&  <WSMaster menu={menu} menuOnchange={_onMenuChange} />}
        {menu === MenuIds.MenuIds.SHADOWCONFIG_WEBSCHED_MAP && (
            <WebScheduler />
        )}
        {menu === MenuIds.MenuIds.SHADOWCONFIG_EDEN_WRITEBACK && (
            <EdenWritebackStatus />
        )}
        {menu === MenuIds.MenuIds.SHADOWCONFIG_BULKUPDATEPAGE && (
            <ShadowBulkOperationLanding />
        )}
        {menu === MenuIds.MenuIds.SHADOWCONFIG_LOCATION_MAP && (
            <LocationMapping />
        )}
        {menu === MenuIds.MenuIds.SHADOWCONFIG_PROVIDER_MAP && (
            <ProviderMapping />
        )}
        {menu === MenuIds.MenuIds.SHADOWCONFIG_APPOINTMENTREASON_MAP && (
            <AppointmentReasonMapping />
                  )}

        {menu === MenuIds.MenuIds.SHADOWCONFIG_SHADOWMANAGEMENT && (
                      <ShadowMgmt />
        )}
        {menu === MenuIds.MenuIds.SHADOWCONFIG_WRITEBACK_SETTING && (
                      <WritebackSetting />
        )}
        {menu === MenuIds.MenuIds.SHADOWCONFIG_TC_DATAVIEWER && (
                      <TcDataViewer />
        )}
        {menu === MenuIds.MenuIds.SHADOWCONFIG_PMSHFCONFIGURATIONS && (
                      < HfConfigurations/>
        )}
        {menu === -1 && <ReminderExclusion/>}
      </Container>      
      {menu === 10 ?  
      <Container maxWidth='xl' disableGutters className="container mx-auto" >
      <Reporting/>
      </Container>  : null}
      {menu === 3 && (
          <Container
            // className={open ? classes.fixedContainerShift : classes.fixedContainer}
            className={clsx(classes.fixedContainer, {[classes.fixedContainerShift]: open})}
            maxWidth={false}
            fixed
          >
            <Coverlookup />
          </Container>
      )}
      {menu === 17 && <PatientForms/>}
      {menu === 18 && <TwoWayText/>} 
	    {(menu >= 19 && menu <= 21) && <TriageReportMaster menu={menu}/>}
      {menu === 22 && <NotificationLogs/>}
      {menu === 23 && <SyncCRMCatalog/>}
      {menu === 24 && <ScriptGenerator/>}
     </main> 
     <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        onClose={_handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"4PC Support Dashboard (Signing out)"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={_onLogout} color="primary">
            Yes
          </Button>
          <Button onClick={_handleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>     
    </div>
  );
}
