import React, { useEffect, useState } from 'react';
import { useUIContext } from '../../../ContextLib/contextHooks';
import { Severity } from '../../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { useV5, V5View } from '../../../ContextLib/CoreConsumer/v5Contexts';
import './V5.scss';

export default function V5MigrationWidget() {
    let componentId = "v5MigrationWidget";
    const ui = useUIContext(componentId);
    const {c, f, Request} = useV5();

    const [status, setStatus] = useState();
    const [version, setVersion] = useState();
    const [remarks, setRemarks] = useState();
    const [hideWidget, setHideWidget] = useState(true);

    const getData = () => {
        if (c.schedules_getData) c.schedules_getData();
    }

    const clearData = async () => {  
        let confirmed = await ui.ConfirmBox("This action will remove all V5 entries. Do you want to continue?");
        if (confirmed == 1) {                    
            ui.ShowOverlay();
            const params = {
                coverKey: f.coverKey,
                locKey: f.locKey,
                acctKey: f.acctKey
            };

            let res = await Request.clear(params);
            if (res.success) {             
                getData();
                ui.ShowSnackbar("All entries were successfully removed", Severity.success, 5000);
            }
            ui.HideOverlay();
        }
    };

    useEffect(()=> {
        setStatus(c.strStatus);
        setVersion(c.nVersion);
        setRemarks(c.strRemarks);
        c.triggerUpdates();
    }, [c.view, c.strStatus, c.nDone]);

    useEffect(()=>{
        if (!c.view.schedules) c.view = {...V5View, utility: true};
    }, [f.locKey, f.acctKey]);

    useEffect(()=>{
        setHideWidget(c.coverAllGood);
    }, [c.coverAllGood]);

    return (<>
        <div className={`v5migration-widget ${hideWidget ? "hide" : ""}`}>
            <div className="widget-handle" onClick={e=>setHideWidget(!hideWidget)}>V5 Data Utility</div>
            <table>
                <tbody>
                    {/* <tr>
                        <td rowSpan={2}>
                            <span className="widget-title">V5 Data Utility</span>                            
                        </td>
                        <td>Status</td>
                        <td>Version</td>
                        <td>Remarks</td>
                    </tr>
                    <tr>
                        <td>{status}</td>
                        <td>{version}&nbsp;</td>
                        <td>{remarks}</td>
                    </tr> */}
                    <tr>
                        <td colSpan={4}>
{/*                             {c.view.schedules ? <a href="#" onClick={()=>c.view = {...V5View, utility: true}}>Open V5 Data Utility</a> :
                                c.view.utility ? <a href="#" onClick={()=>c.view = {...V5View, schedules: true}}>Back to Schedules</a> : 
                                c.view.legacyData ? (<>
                                    <a href="#" onClick={()=>c.view = {...V5View, schedules: true}}>Back to Schedules</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                    <a href="#" onClick={()=>c.view = {...V5View, utility: true}}>Back to list</a>
                                </>) : <></>
                            } */}
                            {c.view.schedules && f.onProviderLevel && (<>
                               {/* <a href="#" onClick={() => getData()}>Reload Entries</a>                                     */}
                                {/* &nbsp;&nbsp;|&nbsp;&nbsp;<a href="#" onClick={() => c.hideV5Entries = !c.hideV5Entries}>{c.hideV5Entries ? "Show V5 Entries" : "Hide V5 Entries"}</a> */}
                                {/* &nbsp;&nbsp;|&nbsp;&nbsp; */}
                                <a href="#" onClick={() => clearData()}>Remove All Entries</a>
                            </>)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>   
    </>);
}