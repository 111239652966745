import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import '../PatientInfo.scss';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import Config from "../general-config.json";
import { useUIContext, useRequest } from '../../../ContextLib/contextHooks';
import { CKEditor } from 'ckeditor4-react';

// import HighlightOffSharpIcon from '@material-ui/icons-material/HighlightOffSharp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useV5 } from '../../../ContextLib/CoreConsumer/v5Contexts';
import Tooltip from '@material-ui/core/Tooltip';
import { generalFieldDisplayNames } from '../General';

export default function GeneralInfo(props) {
  const componentId = "generalInfo";
  const ui = useUIContext(componentId);

  const { f } = useV5();

  const [model, setModel] = useState(props.model);
  const [ckeditor, setCkeditor] = useState(null);
  const [invalidEmailText, setInvalidEmailText] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [checked, setChecked] = useState(false);
  const [Agnosticchecked, setAgnosticChecked] = useState(false);
  const [coverLevelDisable, setCoverLevelDisable] = useState(false);

  const makeUrl = (type) => {
    const fn = type === 1 ? "mobile" : type === 2 ? "desktop" : "profile";
    const urlAcctKey = (type === 1 || type === 2) ? 0 : f.acctKey; //for logos, always use 0 for acct, since they live at cover or loc level only
    return `${process.env.REACT_APP_S3_URL}/${f.coverKey}/${f.locKey}/${urlAcctKey}/${fn}.png`;
  }

  const handleNotificationEmail = (event) => {
    setChecked(event.target.checked);

    if (event.target.checked === true) {
      onChange("emailSchedTo", '');
      setOpenSnackBar(true);
    }
  }

  const handleProviderAgnostic = (event) => {
    var val = event.target.checked ? 1 : 0;
    setAgnosticChecked(event.target.checked);
    onChange("providerAgnostic", val);
  }

  const setUrl = (type, clear) => {
    const url = clear ? "" : makeUrl(type);

    if (type === 1) {
      props.model.mLogo = url;
      onChange("mLogo", url);
    } else if (type === 2) {
      props.model.nmImg1 = url;
      onChange("nmImg1", url);
    } else {
      props.model.profilePic = url;
      onChange("profilePic", url);
    }
  }

  const setImage = (type, img) => {
    type == 1 ? props.setMobileImage(img) : type == 2 ? props.setDesktopImage(img) : props.setProfileImage(img);
    setUrl(type, !img);
  }

  const checkFile = (file, type) => {
    let err = [];

    if (file.type != "image/png") {
      err.push("Invalid file type");
    }

    if (file.size > 4000000) {
      err.push("File exceeded size limit");
    }

    if (err.length > 0) {
      const title = type == 1 ? "Mobile Logo" : type == 2 ? "Desktop Logo" : "Profile Picture";
      const msg = err.join("<br />");
      ui.ErrorBox(msg, title);
    }

    return err.length == 0;
  }

  const handleFile = async (ev, type) => {
    const files = [...ev.target.files];

    if (files.length == 0) {
      setImage(type, null);
      return;
    }

    var f = files[0];
    if (!checkFile(f, type)) {
      setImage(type, null);
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(f);
    fileReader.onload = (e) => {
      setImage(type, e.target.result);
    };
  };

  const onChange = (fieldName, value) => {
    let obj = {};
    obj[fieldName] = value;
    setModel({ ...model, ...obj });

    props.onChange && props.onChange(fieldName, value);
  };

  useEffect(() => {
    setCoverLevelDisable(!(f.coverKey > 0 && f.locKey === 0 && f.acctKey === 0))
    setModel(props.model);
  }, [props.model]);


  useEffect((e) => {
    ckeditor && ckeditor.setData(props.model.endingMesg);
  }, [props.model.endingMesg]);

  useEffect(() => {
    var val = props.model.providerAgnostic === '1' ? true : false
    setAgnosticChecked(val)
  }, [props.model.providerAgnostic]);

  return (
    <div className="ws-general-info">
      <Grid className="title-row" container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
        <Grid item xs={12} sm={6}>
          <h3>Web Schedule</h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <h3>Scheduling Behaviors</h3> */}
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="Enabled">{generalFieldDisplayNames.enabled}</InputLabel>
          <Select
            id="Enabled"
            value={model.enabled}
            onChange={e => onChange("enabled", e.target.value)}
          >
            <MenuItem value={0}>Disabled</MenuItem>
            <MenuItem value={1}>Enabled</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="MaxSchedWeeksOut">{generalFieldDisplayNames.maxSchedWeeksOut} <small>(May be scheduled)</small></InputLabel>
          <TextField
            id="MaxSchedWeeksOut"
            type="number"
            fullWidth={true}
            value={model.maxSchedWeeksOut}
            onChange={e => {
              if (e.target.value == '') e.target.value = 0;
              onChange("maxSchedWeeksOut", parseInt(e.target.value));
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>

        <Grid item xs={12} sm={6}>
          <Tooltip title="This setting can only be applied at cover level. Any changes made at location/provider are overwritten by cover level setting" aria-label="">
            <FormControlLabel
              disabled={coverLevelDisable}
              control={
                <Checkbox style={{ padding: 4 }} defaultChecked color="default" checked={Agnosticchecked} onChange={handleProviderAgnostic} />
              }
              label={
                <span style={{ fontSize: '15px' }}> {generalFieldDisplayNames.providerAgnostic}</span>
              }
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Tooltip title="This setting can only be applied at cover level. Any changes made at location/provider are overwritten by cover level setting" aria-label="">
            <FormControlLabel
              disabled={coverLevelDisable}
              control={
                <Checkbox style={{ padding: 4 }} defaultChecked color="default" checked={model.limitOneAppt > 0} onChange={e => onChange("limitOneAppt", e.target.checked ? 1 : 0)} />
              }
              label={
                <span style={{ fontSize: '15px' }}> {generalFieldDisplayNames.limitOneAppt}</span>
              }
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid className="title-row" container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
        <Grid item xs={12} sm={6}>
          <h3>Email Behaviors</h3>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="SendPatWSDataInEmail">{generalFieldDisplayNames.sendPatWSDataInEmail}</InputLabel>
          <Select
            id="SendPatWSDataInEmail"
            value={model.sendPatWSDataInEmail}
            onChange={e => onChange("sendPatWSDataInEmail", e.target.value)}
          >
            <MenuItem value={0}>Do not send</MenuItem>
            <MenuItem value={1}>Send Patient Data by Link in Email</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="SendWSDataInEmail">{generalFieldDisplayNames.sendWSDataInEmail}</InputLabel>
          <Select
            id="SendWSDataInEmail"
            value={model.sendWSDataInEmail}
            onChange={e => onChange("sendWSDataInEmail", e.target.value)}
          >
            <MenuItem value={1}>Require Login to Access Appt Data</MenuItem>
            <MenuItem value={0}>Include Appt Data in Email</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="NotificationEmail">{generalFieldDisplayNames.emailSchedTo}</InputLabel>
          <TextField
            id="NotificationEmail"
            fullWidth={true}
            value={model.emailSchedTo}
            title={model.notificationEmailObject.dataRemarks}
            helperText={invalidEmailText}
            error={invalidEmailText}
            onChange={e => {
              let x = props.isValidEmailAddress(e.target.value);
              setInvalidEmailText(x ? null : 'Invalid email entry');
              onChange("emailSchedTo", e.target.value);
            }}
          />

        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox style={{ padding: 4 }} defaultChecked color="default" checked={checked} onChange={handleNotificationEmail} />
            }
            label={
              <span style={{ fontSize: '15px' }}> Select the checkbox to remove notification email and leave it blank</span>
            }
          />
        </Grid>
      </Grid>
      <Grid className="title-row" container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
        <Grid item xs={12} sm={6}>
          <h3>Add Mobile Files</h3>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="MobileLogoUrl">Logo Url</InputLabel>
          <TextField
            id="MobileLogoUrl"
            placeholder="Logo URL"
            fullWidth={true}
            value={model.mLogo}
            title={model.mLogo}
            inputProps={{ readonly: true, disabled: true }}
          />
          <LegacyWarning legacyLogo={props.legacyMobileLogo} f={f} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="MobileLogoFile">Attach Logo</InputLabel>
          <TextField
            id="MobileLogoFile"
            type="file"
            fullWidth={true}
            onChange={(e) => handleFile(e, 1)}
          />
          <div><small>Max size: 4 MB, format allowed: png</small></div>
        </Grid>
      </Grid>
      <Grid className="title-row" container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
        <Grid item xs={12} sm={6}>
          <h3>Add Desktop Files</h3>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="DesktopLogoUrl">Logo Url</InputLabel>
          <TextField
            id="DesktopLogoUrl"
            placeholder="Logo URL"
            fullWidth={true}
            value={model.nmImg1}
            title={model.nmImg1}
            inputProps={{ readonly: true, disabled: true }}
          />
          <LegacyWarning legacyLogo={props.legacyDesktopLogo} f={f} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="DesktopLogoFile">Attach Logo</InputLabel>
          <TextField
            id="DesktopLogoFile"
            type="file"
            fullWidth={true}
            onChange={(e) => handleFile(e, 2)}
          />
          <div><small>Max size: 4 MB, format allowed: png</small></div>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="ProfilePic">{generalFieldDisplayNames.profilePic}</InputLabel>
          <TextField
            id="ProfilePic"
            placeholder="Profile Picture Label"
            fullWidth={true}
            value={model.profilePic}
            title={model.profilePic}
            inputProps={{ readonly: true, disabled: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="ProfilePicFile">Attach Profile Picture</InputLabel>
          <TextField
            id="ProfilePicFile"
            type="file"
            fullWidth={true}
            onChange={(e) => handleFile(e, 3)}
          />
          <div><small>Max size: 4 MB, format allowed: png</small></div>
        </Grid>
      </Grid>
      <Grid className="title-row" container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
        <Grid item xs={12} sm={6}>
          <h3>{generalFieldDisplayNames.endingMesg}</h3>
        </Grid>
      </Grid>

      <CKEditor
        editorUrl="/ckeditor4/ckeditor.js"
        onInstanceReady={(e) => {
          setCkeditor(e.editor);
          e.editor.setData(props.model.endingMesg);
        }}
        onChange={(e) => {
          let value = e.editor.getData();
          props.setEndingMsg(value);
        }}
      />
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={10000} onClose={() => setOpenSnackBar(false)}>
        <Alert onClose={() => setOpenSnackBar(false)} severity="info">
          You are about to save an empty Notification Email.
        </Alert>
      </Snackbar>
    </div>
  );
};

const LegacyWarning = ({ legacyLogo, f }) => {
  const coverLocStr = `for cover ${f.coverKey}${f.locKey > 0 ? ` and location ${f.locKey}` : ''}`;
  return <>
    {legacyLogo !== undefined &&
      <>
        {legacyLogo === null ?
          <span>
            No logo is currently being displayed in V6 {coverLocStr}. Upload a new logo to fix this.
          </span> :
          <span>
            V6 currently displays:<br /><b>{legacyLogo}</b><br />{coverLocStr}
          </span>
        }
      </>
    }
  </>
}
