import React, { useState, useEffect } from "react";
import { Button, Divider } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import MaterialCheckBox from "./../../Components/Common/MaterialCheckBox";
import MaterialTableSelect from "./../../Components/Common/MaterialTableSelect";
import TextBoxWithValidator from "../../Components/Common/TextBoxWithValidator";

export default function ShadowMgmtMemberForm({
  listOfShadowDbEngine,
  listOfShadowDbIpAddress,
  listOfShadowDataType,
  listOfPmsDetails,
  selectedShadowMember,
  shadowDbCoverDetail,
  onSubmitMemberForm,
  isViewingDataActive,
  onChangeOfViewingDataActive,
  onExpandLayout,
  expandLayoutActive,
}) {
  const [mockDbIpEngine, setMockDbIpEngine] = useState([]);

  const [mockMemberConfig, setMockMemberConfig] = useState({
    coverKey: 0,
    shadowDbEngine: "",
    shadowDbDatasetType: "",
    shadowDbIPAddress: "",
    pmsUserName: "",
    pmsPassword: "",
    pmsRefID: "",
    organizationID: "",
    pmsURL: "",
    remoteBladeCommands: "",
    disableRemoteSyncProcess: false,
    anonymize: false,
    forcedOverrideConfigPropertyMonikers: "",
    pmsConnectionString: "",
    bladeConfigurationLocalInstance: "",
  });

  useEffect(() => {
    let mockDbEngine = "";

    const doesExist = mockDbIpEngine.find(
      (n) => n.name === mockMemberConfig.shadowDbEngine
    );
    if (doesExist !== undefined) mockDbEngine = mockMemberConfig.shadowDbEngine;

    setMockMemberConfig({ ...mockMemberConfig, shadowDbEngine: mockDbEngine });
  }, [mockDbIpEngine]);

  useEffect(() => {
    setMockDbIpEngine(listOfShadowDbEngine);
  }, [listOfShadowDbEngine]);

  useEffect(() => {
    const mockData = {
      coverKey: 0,
      shadowDbEngine: "",
      shadowDbDatasetType: "",
      shadowDbIPAddress: "",
      pmsUserName: "",
      pmsPassword: "",
      pmsRefID: "",
      organizationID: "",
      pmsURL: "",
      remoteBladeCommands: "",
      disableRemoteSyncProcess: false,
      anonymize: false,
      pmsConnectionString: "",
      bladeConfigurationLocalInstance: "",
    };

    let shadowDbIPAddress =
      shadowDbCoverDetail && shadowDbCoverDetail.shadowDbIPAddress;
    mockData.shadowDbEngine =
      shadowDbCoverDetail && shadowDbCoverDetail.shadowDbEngine;
    mockData.shadowDbDatasetType =
      shadowDbCoverDetail == null
        ? "None"
        : shadowDbCoverDetail.shadowDbDatasetType;

    mockData.shadowDbIPAddress = shadowDbIPAddress;
    mockData.coverKey = parseInt(selectedShadowMember.coverKey);
    mockData.pmsUserName = GetObjValue("pmsusername");
    mockData.pmsPassword = GetObjValue("pmspassword");
    mockData.pmsRefID = GetObjValue("pmsrefid");
    mockData.organizationID = GetObjValue("organizationid");
    mockData.pmsURL = GetObjValue("pmsurl");
    mockData.remoteBladeCommands = GetObjValue("remotebladecommands");
    mockData.disableRemoteSyncProcess = GetObjValue(
      "disableremotesyncprocess",
      true
    );
    mockData.anonymize = GetObjValue("anonymize", true);
    mockData.forcedOverrideConfigPropertyMonikers = GetObjValue(
      "forcedoverrideconfigpropertymonikers"
    );
    mockData.pmsConnectionString = GetObjValue("pmsconnectionstring");
    mockData.bladeConfigurationLocalInstance = GetObjValue(
      "bladeconfigurationlocalinstance"
    );

    HandeChangeOfIpAddress(undefined, shadowDbIPAddress);
    setMockMemberConfig(mockData);
  }, [listOfPmsDetails, shadowDbCoverDetail]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const RenderButton = () => {
    return (
      <>
        {isViewingDataActive && (
          <Button
            type="button"
            variant="outlined"
            color="primary"
            style={{ marginRight: 5 }}
            onClick={() => onChangeOfViewingDataActive(false)}
          >
            Edit
          </Button>
        )}
        {!isViewingDataActive && (
          <>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ marginRight: 5 }}
            >
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              style={{ marginRight: 5 }}
              onClick={() => onChangeOfViewingDataActive(true)}
            >
              Cancel
            </Button>
          </>
        )}
      </>
    );
  };

  const _handleSubmitMemberForm = (data) => onSubmitMemberForm(data);

  const GetObjValue = (searchIndex, boolean) => {
    let findRecord = listOfPmsDetails.find(
      (n) => n.attribName.toLowerCase() == searchIndex
    );
    if (findRecord === undefined) return boolean ? false : "";

    if (findRecord.attribValue === "True") return true;
    if (findRecord.attribValue === "False") return false;

    return findRecord.attribValue;
  };

  const setValue = (e, isCheckBox = false) => {
    const { name, value, checked } = e.target;

    let val = value;

    if (isCheckBox) val = checked;

    setMockMemberConfig({
      ...mockMemberConfig,
      [name]: val,
    });
  };

  const HandeChangeOfIpAddress = (e, initialValue) => {
    if (
      e == undefined &&
      (initialValue === undefined || initialValue == null || initialValue == "")
    )
      return;

    let filter = initialValue === undefined ? e.target.value : initialValue;

    var ipAddValue = [...listOfShadowDbIpAddress].filter(
      (n) => n.name === filter
    );

    var newData = [...listOfShadowDbEngine].filter(
      (n) => n.value === ipAddValue[0].value
    );

    setMockDbIpEngine(newData);
    initialValue === undefined && setValue(e);
  };

  return (
    <>
      <h1 className="pmstitle-title__wrapper t">Member Configuration</h1>
      <ValidatorForm
        onSubmit={() => _handleSubmitMemberForm(mockMemberConfig)}
        onError={(errors) => console.log(errors)}
        className="memberconfiguration-form__wrapper"
      >
        <Button
          type="button"
          variant="outlined"
          color={expandLayoutActive ? "secondary" : "primary"}
          style={{ marginBottom: 15 }}
          onClick={onExpandLayout}
        >
          {expandLayoutActive ? "Shrink" : "Expand"}
        </Button>
        <div className="memberconfiguration__wrapper">
          <TextBoxWithValidator
            readOnly={true}
            label="CoverKey"
            value={mockMemberConfig.coverKey || 0}
            name="coverKey"
          />
          <MaterialTableSelect
            data={mockDbIpEngine}
            readOnly={isViewingDataActive}
            label="ShadowDbEngine"
            value={mockMemberConfig.shadowDbEngine || ""}
            name="shadowDbEngine"
            onChange={(e) => setValue(e)}
            displayKey="name"
            displayAttribute="name"
          />
          <MaterialTableSelect
            data={listOfShadowDataType}
            readOnly={isViewingDataActive}
            label="ShadowDbDatasetType"
            value={mockMemberConfig.shadowDbDatasetType || ""}
            name="shadowDbDatasetType"
            onChange={(e) => setValue(e)}
          />
          <MaterialTableSelect
            data={listOfShadowDbIpAddress}
            readOnly={isViewingDataActive}
            label="Shadow API URL"
            value={mockMemberConfig.shadowDbIPAddress || ""}
            name="shadowDbIPAddress"
            onChange={(e) => HandeChangeOfIpAddress(e)}
            displayKey="name"
            displayAttribute="name"
          />
          <Divider style={{ margin: "15px 0 20px" }} />
          <Divider style={{ margin: "15px 13px 20px" }} />
          <TextBoxWithValidator
            required={false}
            label="PMSUserName"
            readOnly={isViewingDataActive}
            value={mockMemberConfig.pmsUserName || ""}
            name="pmsUserName"
            onInput={(e) => setValue(e)}
          />
          <TextBoxWithValidator
            required={false}
            label="PMSPassword"
            readOnly={isViewingDataActive}
            value={mockMemberConfig.pmsPassword || ""}
            name="pmsPassword"
            onInput={(e) => setValue(e)}
            type="password"
          />
          <TextBoxWithValidator
            required={false}
            label="PMSRefID"
            readOnly={isViewingDataActive}
            value={mockMemberConfig.pmsRefID || ""}
            name="pmsRefID"
            onInput={(e) => setValue(e)}
          />
          <TextBoxWithValidator
            required={false}
            label="OrganizationID"
            readOnly={isViewingDataActive}
            value={mockMemberConfig.organizationID || ""}
            name="organizationID"
            onInput={(e) => setValue(e)}
          />
          <TextBoxWithValidator
            required={false}
            label="PMSURL"
            readOnly={isViewingDataActive}
            value={mockMemberConfig.pmsURL || ""}
            name="pmsURL"
            onInput={(e) => setValue(e)}
          />
          <TextBoxWithValidator
            required={false}
            label="Property Monikers"
            readOnly={isViewingDataActive}
            value={mockMemberConfig.forcedOverrideConfigPropertyMonikers || ""}
            name="forcedOverrideConfigPropertyMonikers"
            onInput={(e) => setValue(e)}
          />

          <div className="pms-member__tooltip rbc">
            <TextBoxWithValidator
              required={false}
              label={"RemoteBladeCommands"}
              multiline={true}
              rows={2}
              readOnly={isViewingDataActive}
              value={mockMemberConfig.remoteBladeCommands || ""}
              name="remoteBladeCommands"
              onInput={(e) => setValue(e)}
            />
            <span className="tooltiptext">
              Documentation available thru this{" "}
              <a
                target="_blank"
                href="https://docs.google.com/document/d/1bZ7ONCuGhcdx-5u8JpokuOGp0L92QtYLxHweDCAGKHE/edit"
              >
                link.
              </a>
            </span>
          </div>

          <div />
          <MaterialCheckBox
            disabled={isViewingDataActive}
            onChange={(e) => setValue(e, true)}
            value={mockMemberConfig.disableRemoteSyncProcess || false}
            name="disableRemoteSyncProcess"
            label="DisableRemoteSyncProcess"
          />
          <MaterialCheckBox
            disabled={isViewingDataActive}
            onChange={(e) => setValue(e, true)}
            value={mockMemberConfig.anonymize || false}
            name="anonymize"
            label="Anonymize"
          />
          <div className="pms-pmsConnectionString__wrapper">
            <TextBoxWithValidator
              required={false}
              label="JSON Config (PMSConnectionString)"
              multiline={true}
              rows={20}
              readOnly={isViewingDataActive}
              value={mockMemberConfig.pmsConnectionString || ""}
              name="pmsConnectionString"
              onInput={(e) => setValue(e)}
            />
          </div>
          <div className="pms-pmsConnectionString__wrapper">
            <TextBoxWithValidator
              required={false}
              label="JSON Config Local"
              multiline={true}
              disabled={true}
              readOnly={true}
              rows={20}
              value={mockMemberConfig.bladeConfigurationLocalInstance || ""}
              name="bladeConfigurationLocalInstance"
              onInput={(e) => setValue(e)}
            />
          </div>
        </div>
        {RenderButton()}
      </ValidatorForm>
    </>
  );
}
